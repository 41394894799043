.model-container {
    max-width: 400px;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    /* Add more styles for background image, etc. */
  }
  
  .avatar-container {
    position: relative;
  }
  
  .avatar {
    height: 64px; /* Adjust the size as needed */
    width: 64px;
    border-radius: 50%;
  }

.status-indicator {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 12px;
    width: 12px;
    background-color: #34D399; /* Tailwind green-400 */
    border-radius: 50%;
    border: 2px solid white;
}


.model-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
    /* ... other styles ... */
  }